﻿header.homepage
{
    position: relative;
}

.intro_container
{
    background-color: $color-gray-lighter;
    padding: 50px;
    position: relative;
    max-width: 1920px;

    @include respond-to(md)
    {
        padding: 50px 0 50px 0;
    }

    .blok
    {
        background: $color-white;
        border: 1px solid $color-gray;
        padding: 20px;
    }

    .titel
    {
        color: $color-orange;
        font-family: $titel-font-family;
        font-size: 35px;
    }

    ul
    {
        margin: 0;
        padding: 0;

        li
        {
            background: url('../../img/list_item.png') no-repeat left 5px;
            padding: 0 0px 6px 40px;
            list-style: none;
            margin: 0;
            overflow: hidden;
        }

        a
        {
            color: $color-black;
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

#homepage_nieuwsbrief
{
    .form-group
    {
        width: 49%;
        display: inline-block;

        @include respond-to(md)
        {
            width: 100%;
        }
    }
}

.mailchimp_nieuwsbrief
{
    input, select
    {
        height: 40px;
    }

    .titel
    {
        font-size: 30px;
        color: $color-yellow;
        font-family: $titel-font-family;
        text-transform: uppercase;
    }

    .aanmelden
    {
        background: $color-black-darker !important;
        color: $color-white;
        border: 0;
        padding: 4px 10px 7px 10px;
        margin: 25px 0 0 0;
        font-size: 17px;
    }

    .ster
    {
        font-weight: 100;
        font-size: 14px;
        line-height: 1.4 !important;
    }

    .datacheck 
    {
        display: flex;

        p
        {
            margin-left: 20px;
            font-weight: 0;
        }

        input {
            width: max-content;
            height: max-content;
        }

        .form-control:focus {
            border-color: none;
        }
    }
}

#voorstellingenhome
{
    background: linear-gradient(left, $color-white 60%, $color-gray-dark 40%);
    background: -webkit-linear-gradient(left, $color-white 60%, $color-gray-dark 40%);
    position: relative;
    max-width: 1920px;
    padding: 0;

    @include respond-to(sm)
    {
        background: $color-yellow;

        [class*='col-']
        {
            padding: 0;
        }
    }
}

.voorstellingen_home
{
    padding: 40px;

    a
    {
        color: $color-black-darker;
    }

    .actueel_item
    {
        margin: 0;
        padding: 0 0 10px 0;
        overflow: hidden;
        display: flex;

        div
        {
            vertical-align: text-top;
            width: 100%;
            border-bottom: 1px solid $color-gray-dark;
            padding-bottom: 8px;
        }

        .first
        {
            padding-right: 48px;
            width: max-content;
            white-space:nowrap;
            border-bottom: 1px solid $color-gray-dark;
            padding-bottom: 8px;
        }
    }

    a.titel
    {
        color: $color-gray-dark;
        font-family: $titel-font-family;
        text-transform: uppercase;
        font-size: 35px;

        &:hover
        {
            text-decoration: none;
        }
    }

    a.meerweten
    {
        position: relative;
        color: $color-gray-dark;
        font-weight: 600;


        .fa-calendar-check-o
        {
            margin: 20px 10px 0 0;
        }
    }
}

.information-home
{
    background: $color-gray-lighter;
    padding: 60px 0;

    .titel
    {
        font-family: $titel-font-family;
        text-transform: uppercase;
        font-size: 45px;
        line-height: 50px;
        margin: 0 0 30px 0;
    }

    .blok
    {
        a
        {
            color: $color-gray-dark;
            text-decoration: underline;
        }
    }

    .nieuws-home
    {
        a
        {
            .item
            {
                display: flex;
                flex-direction: row;
                background: $color-white;
                margin-bottom: 30px;

                @include respond-to (xxs)
                {
                    flex-direction: column;
                }

                img
                {

                    @include respond-to (xxs)
                    {
                        width: 100%;
                    }
                }

                .tekst
                {
                    padding: 15px;
                    color: $color-gray-dark;
                }
            }
        }
    }

    .meer-nieuws
    {
        position: relative;
        color: $color-gray-dark;
        font-weight: 600;
    }
}


#trailer-modal
{
    z-index: 10000;
    color: $color-white;

    .modal-header .close
    {
        color: $color-white;
    }

    .modal-content
    {
        background: $color-black-darker;
    }
}
