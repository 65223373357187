h1, h2, h3, h4, h5 {
    font-family: 'Plak W05 Black Condensed';
    text-transform: uppercase;
}

/**umb_name:Kop 1*/
h1 {
font-size: 30px;
font-weight: 300;
line-height: 1.3em;
margin: 0 0 15px 0;
padding: 0;}

/**umb_name:Kop 2*/
h2.tussenkop{
font-size: 24px;
font-weight: 300;
line-height: 1.3em;
margin: 0;
padding: 0;}

/**umb_name:Kop 3*/
h3.tussenkop{
font-size: 21px;
font-weight: 300;
line-height: 1.3em;
margin: 0;
padding: 0;}

/**umb_name:Kop 4*/
h4.tussenkop{
font-size: 16px;
font-weight: 100;
line-height: 1.3em;
margin: 0;
padding: 0;}

/**umb_name:Afbeelding links*/
.AfbeeldingLinks{float:left;
margin:0 20px 20px 0;}

/**umb_name:Afbeelding rechts*/
.AfbeeldingRechts{float:right;
margin:0 0 20px 20px;}