﻿form
{
    input[type=checkbox], input[type=radio]
    {
        width: auto;
        height: auto;
        display: inline-block;
    }

    .form-horizontal .checkbox, .form-horizontal .radio
    {
        border: none;
        box-shadow: none;
        padding-left: 0;

        label
        {
            font-weight: 700;
            font-size: 16px;
            color: #333;
        }
    }
}
