﻿.content_intro
{
    margin-bottom: 20px;
    max-width: 1920px;

    a
    {
        color: $color-black-darker;
        font-weight: 600;
    }
}

.center_tekst
{
    text-align: center;
}

.contact_container
{
    max-width: 1920px;
}

.contactformulier
{
    background: #f1eee9;
    overflow: hidden;
    margin: 0 0 50px 0;

    fieldset
    {
        margin: 0;
        padding: 0;
    }

    .input.text
    {
    }

    .btn
    {
        background: $color-black;
        color: $color-white;
        border-radius: 0;
        margin: 0 0 20px 0;
    }

    .umbraco-forms-field
    {
    }
}


.container
{
    .row.equal-cols
    {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .row.equal-cols:before,
    .row.equal-cols:after
    {
        display: block;
    }

    .row.equal-cols > [class*='col-']
    {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .row.equal-cols > [class*='col-'] > *
    {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }
}
