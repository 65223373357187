﻿// =============================================================================
// _mixins.scss | Author: Nexwork
// =============================================================================

@mixin respond-to($breakpoint, $custom: null)
{
    @if ($custom)
    {
        @media (#{$custom}-width: $breakpoint)
        {
            @content;
        }
    }
    @else
    {
        @if $breakpoint == "xxs"
        {
            @media (max-width: $screen-xxs-max)
            {
                @content;
            }
        }

        @if $breakpoint == "xs"
        {
            @media (max-width: $screen-xs-max)
            {
                @content;
            }
        }

        @else if $breakpoint == "sm"
        {
            @media (max-width: $screen-sm-max)
            {
                @content;
            }
        }

        @else if $breakpoint == "md"
        {
            @media (max-width: $screen-md-max)
            {
                @content;
            }
        }

        @else if $breakpoint == "lg"
        {
            @media (min-width: $screen-lg-min)
            {
                @content;
            }
        }

        @else if $breakpoint == "xl"
        {
            @media (min-width: $screen-xl-min)
            {
                @content;
            }
        }
    }
}