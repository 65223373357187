﻿.quote_home {
    background: $color-gray-dark;
    min-height: 540px;
    color: $color-white;
    padding: 80px 0 0 0;
    -webkit-clip-path: polygon(100% 100%, 100% 0, 0 0, 5% 100%);
    clip-path: polygon(100% 100%, 100% 0, 0 0, 5% 100%);

    @include respond-to(sm) {
        margin-bottom: -40px;
        min-height: auto;
        -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 5%);
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 5%);
    }

    .quote_slider_home {
        @include respond-to(sm) {
            padding: 9rem 6rem;
        }

        @include respond-to(xs) {
            padding: 6rem 3rem;
        }

        @include respond-to(xxs) {
            padding: 6rem 0;
        }
    }

    .item {
        overflow: hidden;
        text-align: center;
        height: auto;
        padding: 0 0 0 100px;

        @include respond-to(sm) {
            padding: 0 50px;
        }

        @include respond-to(xxs) {
            padding: 0 15px;
        }

        p {
            font-size: 60px;
            line-height: 65px;
            font-family: $titel-font-family;
            color: $color-yellow;
            margin: 0;

            @include respond-to(sm) {
                font-size: 4rem;
                line-height: 4.25rem;
            }
        }
    }
}
