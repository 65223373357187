﻿.onze_mensen
{
    background: #ECECEC;
    padding: 30px 0 60px 0;

    .first
    {
        display: flex;
        margin: 30px 0 0 0;

        img
        {
            height: 220px;
            margin: 0 30px 0 0;
        }

        h3
        {
            color: $color-black;
            font-size: 56px;
            line-height: 63px;
            text-transform: none;
        }
    }

    .carousel-showmanymoveone
    {
        position: relative;
        margin: 30px 0 0 0;

        .item
        {
            display: block;
            position: relative;
            margin: 0 15px;

            img
            {
                width: 100%;
            }

            .naamacteur
            {
                position: absolute;
                bottom: -10px;
                width: 100%;
                padding: 10px;
                color: $color-white;
                text-align: center;
                background: $color-black;
            }
        }

        .slick-arrow
        {
            background-color: transparent;
            border: 0;
            position: absolute;
            top: 40%;
            z-index: 10;
            display: block;
            width: 25px;
            height: 50px;
            color: $color-black;
            text-indent: -9999px;
        }

        .slick-prev
        {
            left: 35px;
            background-image: url('../../img/arrow_prev_bl.png');
        }

        .slick-next
        {
            right: 35px;
            background-image: url('../../img/arrow_next_bl.png');
        }
    }
}
