﻿#homepage_slider
{
    position: relative;

    .slick-prev
    {
        position: absolute;
        top: 280px;
        left: 20px;
        z-index: 9900;
        width: 41px;
        height: 82px;
        background-image: url('../../img/arrow_prev.png');
        cursor: pointer;

        @include respond-to(sm)
        {
            display: none !important;
        }
    }

    .slick-next
    {
        position: absolute;
        top: 280px;
        right: 20px;
        z-index: 9900;
        width: 41px;
        height: 82px;
        background-image: url('../../img/arrow_next.png');
        cursor: pointer;

        @include respond-to(sm)
        {
            display: none !important;
        }
    }

    .item
    {
        max-width: 1920px;
        margin: 0 auto;
        position: relative;
        max-height: 500px;
        overflow: visible;
        z-index: 10;

        .slider_wrap
        {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 1170px;
            margin-left: auto;
            margin-right: auto;
            right: 0;
            height: 500px;

            .inner
            {
                position: relative;
                height: 500px;

                .info
                {
                    position: absolute;
                    top: 150px;
                    left: 0;
                    text-decoration: none;
                    outline: none;

                    @include respond-to(md)
                    {
                        left: 30px;
                        z-index: 9999;
                    }

                    .titel, .subtitel
                    {
                        font-family: $titel-font-family;
                        color: $color-white;
                        text-transform: uppercase;
                    }

                    .titel
                    {
                        font-size: 90px;
                        line-height: 95px;

                        @include respond-to(md)
                        {
                            font-size: 80px;
                            line-height: 85px;
                        }

                        @include respond-to(sm)
                        {
                            font-size: 35px;
                            line-height: 40px;
                        }
                    }

                    .subtitel
                    {
                        font-size: 50px;
                        line-height: 55px;

                        @include respond-to(md)
                        {
                            font-size: 40px;
                            line-height: 45px;
                        }

                        @include respond-to(sm)
                        {
                            font-size: 20px;
                            line-height: 25px;
                        }
                    }
                }

                .voorgrond
                {
                    position: absolute;
                    bottom: -48px;
                    right: -120px;
                    z-index: 9999;
                    width: 852px;
                    height: 670px;

                    @include respond-to(sm)
                    {
                        z-index: 1;
                    }

                    @include respond-to(xs)
                    {
                        width: 590px;
                        height: 464px;
                    }
                }

                .extrainfo
                {
                    position: absolute;
                    bottom: 80px;
                    left: 0;
                    z-index: 9999;
                    font-weight: bold;
                    color: $color-white;

                    @include respond-to(md)
                    {
                        left: 30px;
                    }

                    .trailer
                    {
                        display: inline-block;
                        color: $color-white;
                        font-size: 30px;
                        margin: 0;
                    }

                    .meerweten
                    {
                        display: block;
                        height: 82px;
                        position: relative;
                        background: #000;
                        color: $color-white;
                        margin: 20px 0 0 0;
                        width: 100%;
                        text-decoration: none;
                        padding: 20px;
                        line-height: 20px;

                        .fa-info
                        {
                            font-size: 50px;
                            float: left;
                            margin: -3px 20px 0 0;
                        }
                    }

                    .meerweten:before
                    {
                        background-image: url('../../img/cta_left.png');
                        width: 22px;
                        height: 82px;
                        content: " ";
                        position: absolute;
                        left: -22px;
                        top: 0;
                    }
                }
            }
        }
    }
}
