﻿footer
{
    background: $color-gray-dark;
    color: $color-white;
    padding: 40px 0 60px 0;
    max-width: 1920px;
    position: relative;

    .sub-title
    {
        color: $color-yellow;
    }

    ul
    {
        margin: 10px 0 10px 0;
        padding: 0;

        li
        {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    a
    {
        color: $color-white;
    }

    a:hover
    {
        color: $color-yellow;
        text-decoration: none;
    }

    .pauw
    {
        background-image: url('../../img/pauw.png');
        position: absolute;
        right: 30px;
        top: -190px;
        width: 228px;
        height: 202px;
        z-index: 9999;
        -webkit-transition: right 1s;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

        @include respond-to(md)
        {
            display: none;
        }
    }

    .pauw:hover
    {
        right: 100px;
    }

    .ijsje
    {
        background-image: url('../../img/Racketmeisje.png');
        position: absolute;
        right: 30px;
        top: -50px;
        width: 167px;
        height: 200px;
        z-index: 9999;
        -webkit-transition: right 1s;

        @include respond-to(md)
        {
            display: none;
        }
    }

    .ijsje:hover
    {
        right: 100px;
    }
}
