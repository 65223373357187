﻿header.content
{
    position: relative;
    margin: 0 0 50px 0;
    max-width: 1920px;

    .header_titel
    {
        font-family: $titel-font-family;
        color: $color-white;
        position: absolute;
        top: 200px;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 60px;
        text-transform: uppercase;
    }
}

.content_header
{
    background-size: cover;
    background-position: center;
    max-width: 100%;
    height: 360px;
    min-height: 300px;
}
