﻿.voorstelling_overzicht
{
    .item
    {
        display: block;
        text-decoration: none;
        border: 1px solid $color-black;
        margin: 0 0 30px 0;
        color: $color-black;
        position: relative;


        .archief
        {
            color: $color-white;

            &.ribbon span
            {
                background: #F8463F;
            }

            &.ribbon span:after
            {
                background: #C02031;
            }

            &.ribbon span:before
            {
                background: #F8463F;
            }
        }

        .jong_laagland
        {
            color: $color-white;

            &.ribbon span
            {
                background: #942724;
            }

            &.ribbon span:after
            {
                background: #6c1917;
            }

            &.ribbon span:before
            {
                background: #942724;
            }
        }

        .theater_na_de_dam
        {
            color: $color-white;

            &.ribbon span
            {
                background: #1258A2;
            }

            &.ribbon span:after
            {
                background: #092c51;
            }

            &.ribbon span:before
            {
                background: #1258A2;
            }
        }

        .verwacht
        {
            color: $color-white;

            &.ribbon span
            {
                background: #009987;
            }

            &.ribbon span:after
            {
                background: #0b5a50;
            }

            &.ribbon span:before
            {
                background: #009987;
            }
        }

        .op_aanvraag
        {
            color: $color-white;

            &.ribbon span
            {
                background: #3E46CB;
            }

            &.ribbon span:after
            {
                background: #212792;
            }

            &.ribbon span:before
            {
                background: #3E46CB;
            }
        }

        .ribbon
        {
            position: absolute;
            top: -7px;
            right: 10px;
        }


        .ribbon span
        {
            position: relative;
            display: block;
            text-align: center;
            background: #F8463F;
            font-size: 14px;
            line-height: 1;
            padding: 12px 20px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 8px;
            width: auto;
        }

        .ribbon span:before, .ribbon span:after
        {
            position: absolute;
            content: "";
        }

        .ribbon span:before
        {
            height: 6px;
            width: 6px;
            left: -6px;
            top: 0;
            background: #F8463F;
        }

        .ribbon span:after
        {
            height: 6px;
            width: 8px;
            left: -8px;
            top: 0;
            border-radius: 8px 8px 0 0;
            background: #C02031;
        }


        .tekst
        {
            padding: 30px;

            .titel
            {
                font-family: $titel-font-family;
                font-size: 24px;
                margin: 0 0 10px 0;
            }

            .leeftijd, .duur
            {
                font-size: 15px;
            }
        }
    }
}

#voorstelling
{
    max-width: 1920px !important;
    position: relative;
    max-height: 663px;
    margin-bottom: 30px;

    .voorstelling_afbeelding
    {
        background-size: cover;
        background-position: center;
        height: 745px;
    }

    #toneelstuk_wrap
    {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        height: 745px;
        z-index:10;

        .inner
        {
            position: relative;
            height: 745px;

            .info
            {
                position: absolute;
                top: 280px;
                left: 0;
                color: $color-white;

                @include respond-to(md)
                {
                    bottom: 0;
                    left: 50px;
                    height: 345px;
                    z-index: 9999;
                }

                .leeftijd, .duur
                {
                    display: block;
                    color: $color-white;
                    font-weight: 600;
                }

                .titel, .subtitel
                {
                    font-family: $titel-font-family;
                    color: $color-white;
                    text-transform: uppercase;
                }

                .titel
                {
                    font-size: 90px;
                    line-height: 95px;

                    @include respond-to(md)
                    {
                        font-size: 80px;
                        line-height: 85px;
                    }

                    @include respond-to(sm)
                    {
                        font-size: 35px;
                        line-height: 40px;
                    }
                }

                .subtitel
                {
                    font-size: 50px;
                    line-height: 55px;
                    margin: 0 0 30px 0;

                    @include respond-to(md)
                    {
                        font-size: 40px;
                        line-height: 45px;
                    }

                    @include respond-to(sm)
                    {
                        font-size: 20px;
                        line-height: 25px;
                    }
                }
            }

            .voorgrond
            {
                position: absolute;
                bottom: -48px;
                right: -120px;

                @include respond-to(lg)
                {
                    right: 2px;
                }

                @include respond-to(md)
                {
                    right: 2px;
                }

                @include respond-to(xs)
                {
                    display: none;
                }
            }

            .pauw
            {
                background-image: url('../../img/pauw.png');
                position: absolute;
                left: -270px;
                bottom: -10px;
                width: 228px;
                height: 202px;
                z-index: 9999;
                -webkit-transition: left 1s;
            }

            .pauw:hover
            {
                left: -250px;
            }
        }
    }
}

#voorstelling.thema-zwart
{
    .info
    {
        color: $color-black !important;

        .leeftijd, .duur
        {
            color: $color-black !important;
            text-shadow: none !important;
        }

        .titel, .subtitel
        {
            color: $color-black !important;
            text-shadow: none !important;
        }
    }
}

.banner_voorstelling
{
    background: $color-blue;
    position: relative;
    max-width: 1920px;

    .titel
    {
        font-family: $titel-font-family;
        font-size: 50px;
        line-height: 60px;
        text-transform: uppercase;
        margin-top: 30%;
        margin-bottom: 50px;

        @include respond-to(lg)
        {
            margin-top: 15%;
            margin-bottom: 30px;
        }

        @include respond-to(md)
        {
            margin-top: 10%;
            margin-bottom: 30px;
        }
    }

    .knop
    {
        background: $color-yellow;
        color: $color-black;
        font-weight: 600;
        padding: 20px;
        font-size: 18px;
        float: left;

        @include respond-to(sm)
        {
            margin-bottom: 50px;
        }
    }

    .knop:hover
    {
        text-decoration: none;
    }

    .afbeelding
    {
        -webkit-clip-path: polygon(95% 100%, 100% 0, 0 0, 0 100%);
        clip-path: polygon(95% 100%, 100% 0, 0 0, 0 100%);
        width: 100%;

        @include respond-to(sm)
        {
            -webkit-clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%);
            clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%);
        }
    }

    .meisje_band
    {
        background: url('../../img/meisje_band.png')no-repeat;
        position: absolute;
        top: 0;
        right: 50px;
        width: 181px;
        height: 419px;
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        z-index: 9999;

        @include respond-to(md)
        {
            display: none;
        }
    }
}

.credits
{
    background: $color-yellow;
    color: $color-black-darker;
    padding: 30px 0;
    max-width: 1920px;

    a
    {
        color: $color-black-darker;
        text-decoration: underline;
    }

    .titel
    {
        font-size: 40px;
        line-height: 50px;
        font-family: $titel-font-family;
        margin: 0 0 30px 0;
    }

    table
    {
        width: 100%;
        margin: 0 0 30px 0;
    }

    .ooksuper
    {
        position: relative;
        margin: 0 0 20px 0;

        a
        {
            text-decoration: none;
        }

        .titelsuper
        {
            position: absolute;
            bottom: 10px;
            margin-left: 10px;
            margin-right: 10px;
            width: 94%;
            padding: 10px 10px 10px 15px;
            background: $color-black;
            color: $color-white;

            .fa
            {
                font-size: 12px;
                float: right;
                padding: 10px;
            }
        }
    }
}

#tickets
{
    font-size: 15px;
    margin-bottom: 10px;
    line-height: 18px;
    padding: 0;

    .item
    {
        display: block;
        margin: 0;
        background: $color-yellow;

        @include respond-to(md)
        {
            margin-bottom: 15px;
        }

        &:hover
        {
            color: $color-white;
            background: $color-yellow-darker;
        }

        .place
        {
            padding: 15px 15px 10px 15px;
            border-bottom: 1px dashed $color-black;
        }

        .date
        {
            padding: 10px 15px 15px 15px;
            display: flex;
            justify-content: space-between;

            span.dag
            {
                font-weight: bold;
                margin-right: 10px;
            }
        }

        a
        {
            color: $color-black;
            text-decoration: none;

            &:hover
            {
                color: $color-white;
            }
        }
    }
}

.content_intro
{
    background: $color-white;
    z-index: 10;
    position: relative;
    padding-top: 40px;
}

.andere_locatie
{
    background: $color-black;
    color: $color-white;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
    display: block;

    .koop_titel
    {
        margin: 0 0 10px 0;
        display: block;
    }

    .kooptickets
    {
        color: $color-white;
        font-weight: bold;
        text-decoration: none;
        font-size: 18px;
    }

    #tooltip
    {
        position: absolute;
        top: 85px;
        left: 0;
        background: $color-white;
        width: 395px;
        z-index: 9999;
        box-shadow: 0 0 10px $color-black;

        @include respond-to(md)
        {
            width: 100%;
        }

        .wrapper
        {
            padding: 30px;

            .titel
            {
                margin: 0 0 20px 0;
                color: $color-black;
                font-weight: bold;
            }
        }

        .bekijk_alles
        {
            background: $color-yellow;
            color: $color-black;
            font-weight: 300;
            padding: 20px;
            font-size: 20px;
        }
    }

    #agenda-results
    {
        margin: 20px 0 0 0;
        padding: 0;
        border-top: 1px solid #ddd4c8;

        .item
        {
            margin-bottom: 15px;
        }

        a
        {
            color: $color-black;
            font-weight: 600;
        }
    }
}

.slider-fotoboek
{
    .slick-arrow
    {
        background-color: transparent;
        border: 0;
        position: absolute;
        top: 40%;
        z-index: 10;
        display: block;
        width: 41px;
        height: 82px;
        color: $color-white;
        text-indent: -9999px;
    }

    .slick-prev
    {
        left: 35px;
        background-image: url('../../img/arrow_prev.png');
    }

    .slick-next
    {
        right: 35px;
        background-image: url('../../img/arrow_next.png');
    }
}
