﻿// =============================================================================
// _menu.scss | Author: Nexwork
// =============================================================================

// Algemene styling
.header_menu
{
    position: relative;
    z-index: 9999;
    max-width: 1920px;
    margin: 0 auto;

    .navbar-collapse
    {
        position: relative;
    }

    .navbar-inverse .navbar-toggle .icon-bar
    {
        background-color: $color-red;
    }

    .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover
    {
        background: transparent;
        color: $color-red;
    }

    .navbar-nav > li > .dropdown-menu
    {

        li
        {
            display: block;

            a
            {
                display: block;
                margin: 0;
                padding: 5px 15px;
            }
        }
    }

    .logo
    {
        display: block;
        width: 160px;
        margin-top: 20px;
        z-index: 9999;
        position: absolute;

        @include respond-to(sm)
        {
            width: 90px;
        }

        @include respond-to(xs)
        {
            margin-left: 15px;
            margin-top:15px;
        }

        img
        {
            width: 160px;

            @include respond-to(xs)
            {
                width: 140px;
                margin-left: 15px;
            }
        }
    }

    .hoofdmenu
    {
        margin-top: 30px;
        margin-right: -18px;

        .menu
        {
            @include respond-to(sm)
            {
                width: 80%;
            }



            li
            {
                list-style: none;
                display: inline;

                @include respond-to(xs)
                {
                    display: block;
                }

                a
                {
                    color: $color-black;
                    text-decoration: none;
                    font-weight: 600;
                    display: inline;
                    margin: 12px 0 0 30px;
                    padding: 0;

                    @include respond-to(xs)
                    {
                        color: $color-white;
                    }

                    &:hover
                    {
                        color: $color-red;
                    }

                    &.active
                    {
                        color: $color-red;
                    }
                }

                a.taal
                {
                    overflow: hidden;
                    text-indent: 100px;
                    display: block;
                    width: 18px;
                    height: 18px;
                    margin: 2px 0 0 8px;

                    &.de-DE
                    {
                        background-image: url('/img/taal_de.png');

                        @include respond-to(xs)
                        {
                            margin-left: 25px;
                            margin-right: 15px;
                        }
                    }

                    &.en-GB
                    {
                        background-image: url('/img/taal_en.png');

                        @include respond-to(xs)
                        {
                            margin-left: 15px;
                        }
                    }
                }

                .fa-search
                {

                    @include respond-to(xs)
                    {
                        margin-top:15px;
                    }
                }
            }

            li.dropdown img
            {
                width: 13px;
                height: 13px;
            }
        }
    }
}

@include respond-to(xs)
{
    .header_menu
    {
        .navbar-collapse
        {
            position: absolute;
            left: 0;
            top: 60px;
            box-shadow: none;
            border: none;
            width: calc(100% + 15px);
            overflow-x: hidden;
            background: $color-black;
            z-index: 1;

            .hoofdmenu
            {
                padding: 120px 15px 60px 15px;
                margin-top: 0;

                a:hover
                {
                    color: $color-white !important;
                }
            }

            .navbar-nav > li > .dropdown-menu
            {
                li
                {
                    display: block;
                    margin: 0 0 0 15px;

                    a
                    {
                        display: block;
                        margin: 0;
                        padding: 5px 15px;
                    }
                }
            }

            .social-links
            {
                margin-left: 0;

                a:hover
                {
                    color: $color-white !important;
                }
            }

            .vlaggen
            {
                display: inline-block;
                width: 30px;
                float: left;
                margin: 15px 0 0 5px;

                a
                {
                    display: inline-block;
                }
            }
        }
    }
}

.navbar-inverse
{
    background: none;
    border: none;
}
