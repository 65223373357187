﻿@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=27e4a95c-547b-43b8-8a43-61d7f1ceb360&fontids=5340226,5364521,5367039");

@font-face
{
    font-family: "Plak W05 Black Extra Condensed";
    src: url("/fonts/5340226/87cf4888-e077-4dc8-9aa7-224fa9447284.woff2") format("woff2"),url("/fonts/5340226/bba84f65-6d65-4a68-9aec-37388322c996.woff") format("woff");
}

@font-face
{
    font-family: "Plak W05 Black";
    src: url("/fonts/5364521/d74f91bc-43ae-4868-b762-8f8f402d2fff.woff2") format("woff2"),url("/fonts/5364521/3553fad7-065c-4276-ac7a-a090cbf77def.woff") format("woff");
}

@font-face
{
    font-family: "Plak W05 Black Condensed";
    src: url("/fonts/5367039/eb73fdb6-556f-45b0-b643-2ea3865c4dbd.woff2") format("woff2"),url("/fonts/5367039/13dab8a9-8701-4a88-a873-42e75865008b.woff") format("woff");
}


// Font
$font-color:                            $color-black-darker;

// Font weights
$light:                                 100;
$regular:                               400;
$bold:                                  600;

// Base Font
$base-font-family:                      'Karla', sans-serif;
$base-font-weight:                      $regular;
$base-font-size:                        16px;
$base-line-height:                      1.6;

// Titel Font
/*$titel-font-family:                     'Anton', sans-serif;*/
$titel-font-family:                     'Plak W05 Black Condensed';


body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
}


.content_titel {
    font-family: $titel-font-family;
    font-size: 90px;
    line-height: 100px;
    color: $color-white;
    text-shadow: 2px 2px 20px $color-black;

    @include respond-to(sm) {
                font-size: 60px;
                line-height: 70px;
                margin: 130px 0 0 0;
            }
}