﻿#resultaat_filter {
    background: $color-white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    margin: -134px 0 30px 0;

    .padding {
        padding: 0 25px 0 25px;
    }

    .titel {
        font-family: $titel-font-family;
        font-size: 25px;
        border-bottom: 1px solid $color-gray;
        margin: 0 25px 25px 25px;
        padding: 25px 0 20px 0;
    }

    .filter_resultaat {
        background: $color-green;
        font-family: $titel-font-family;
        font-size: 20px;
        color: $color-white;
        text-align: left;
        padding: 5px 25px 5px 25px;
        border: 0;
        margin: 25px 0 0 0;
    }

    .fa-angle-double-right {
        float: right;
        padding: 5px 0 5px 25px;
    }
}

#archief {
    margin: 25px 0 25px 0;
    padding: 80px 25px 30px 25px;
    background-image: url('../../img/archief_bg.jpg');

    @include respond-to(sm) {
        display: none;
    }

    .btn_archief {
        background: $color-blue;
        padding: 5px;
        color: $color-white;
        text-align: center;
        font-size: 17px;
    }

    a:hover {
        text-decoration: none;
    }
}

.maandoverzicht {
    margin: 0 0 30px 0;
    position: relative;

    tbody {
        background: $color-gray-lighter;
    }

    th {
        vertical-align: middle;
    }

    a {
        color: $color-green;
        text-decoration: underline;
    }

    .maand {
        font-family: $titel-font-family;
        font-size: 18px;
        font-weight: normal;
    }

    td.kopen {
        background: $color-green;
        text-align: center;
        vertical-align: middle;

        a {
            color: $color-white;
            display: block;
            padding: 10px;
        }
    }

    td.kopenniet {
        background: $color-green-lighter;
        text-align: center;
        vertical-align: middle;
        color: $color-white;
    }

    th.kopen {
        text-align: center;
        vertical-align: middle;
    }
}

.agenda_header {
    position: relative;

    .vliegtuig {
        background-image: url('../../img/vliegtuig.png');
        position: absolute;
        right: 30px;
        bottom: -80px;
        width: 501px;
        height: 239px;
        z-index: 9998;

        @include respond-to(md) {
            display: none;
        }
    }

    .propellor {
        position: absolute;
        bottom: 18px;
        right: 392px;
        width: 39px;
        height: 159px;
        margin: -60px 0 0 -60px;
        -webkit-animation: spin 0.5s linear infinite;
        -moz-animation: spin 0.5s linear infinite;
        animation: spin 0.5s linear infinite;
        z-index: 9999;

        &:hover {
            -webkit-animation: spin 0.2s linear infinite;
            -moz-animation: spin 0.2s linear infinite;
            animation: spin 0.2s linear infinite;
        }

        @include respond-to(md) {
            display: none;
        }
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .pauw {
        background-image: url('../../img/pauw.png');
        position: absolute;
        left: 30px;
        bottom: -10px;
        width: 228px;
        height: 202px;
        z-index: 9999;
        -webkit-transition: left 1s;

        @include respond-to(md) {
            display: none;
        }
    }

    .pauw:hover {
        left: 100px;
    }
}

.footerbanner_agenda {
    position: relative;

    .giraffe {
        background-image: url('../../img/giraffe.png');
        position: absolute;
        left: 30px;
        bottom: 0;
        width: 363px;
        height: 800px;
        z-index: 9999;
    }

    .giraffe:hover {
    }
}

.meer_voorstellingen {
    margin: 0 0 120px 0;

    a#meer-voorstellingen {
        color: $color-green;
        font-weight: 600;
        text-decoration: underline;
    }
}

.agenda_container {
    position: relative;
    max-width: 1920px;

    .quote_slider {
        position: absolute;
        top: -210px;
        left: 20px;
        max-width: 400px;
        display: block;
        color: $color-white;
        font-family: $titel-font-family;
        font-size: 65px;
        margin: 30px 0 0 0;
        text-shadow: 0px 0px 15px $color-black;
        z-index: 9999;

        @include respond-to(sm) {
            display: none;
        }

        .komma {
            background: url('../../img/quote_komma.png') no-repeat;
            color: $color-white;
            font-size: 18px;
            font-family: $base-font-family;
            padding: 0 0 0 50px;
            min-height: 60px;
        }
    }

    .mailchimp_nieuwsbrief {
    @include respond-to(xs)
        {
        display: none;
        }
    }
}
