﻿.nieuws_container {
    max-width: 1920px;
}

.nieuwsoverzicht_item {
    border: 1px solid $color-gray;
    display: block;
    overflow: hidden;
    margin: 0 0 30px 0;
    padding: 0;
    color: $color-black;
    text-decoration: none;

    .nieuwsoverzicht_afbeelding {
        @include respond-to(sm)
            {
            display: none;
            }
    }

    .titel {
        margin: 15px 0;
        font-size: 35px;
        line-height: 40px;
        font-family: $titel-font-family;
    }

    time {
        font-style: italic;
    }

    .tekst {
        margin: 15px 15px 0 0;

        @include respond-to(md)
            {
            padding: 30px;
            }

        p {
            max-height: 130px;
            overflow: hidden;
            line-height: 25px;
            height: 100px;
            overflow: hidden;
            background: -webkit-linear-gradient(#000, #fff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.nieuwsoverzicht_item:hover,  .nieuwsoverzicht_item:focus{
    border: 1px solid $color-black;
    color: $color-black;
}

.nieuwsbericht {
    max-width: 1920px;
    padding: 0 0 0 0;

    @include respond-to(md)
            {
                margin: 0 30px 0 30px;
            }

    a {
        color: $color-black;
        text-decoration: underline;
    }
    .subtitel {
        font-style: italic;
        margin: 30px 0;
        font-size: 30px;
        font-family: $titel-font-family;
    }
    .intro {
        font-weight: 600;
        margin: 30px 0;
    }
    .afbeelding {
        margin: 30px 0;
    }
    .auteur {
        margin: 30px 0;
    }
    .btn {
        color: $color-white;
        border-radius: 0;
        padding: 10px 20px;
        margin: 30px 0;
        text-decoration: none;
    }

    .laatsteitems {
        margin: 30px 0;
        padding: 0;
        a{
            color: $color-black;
            font-weight: 600;
        }

        li {
            background-color: $color-white;
            border: 1px solid $color-gray;
            margin-bottom: 10px;
            padding: 0;
            list-style: none;

            .tekst {
                line-height: 21px;
                margin: 5px 0 0 0;
            }
        }
    }
}

header.nieuws_header .content_titel {
    font-size: 60px;
    line-height: 70px;

    @include respond-to(md)
            {
                font-size: 50px;
                line-height: 60px;
                margin: 60px 0 0 0;
            }
    @include respond-to(sm)
            {
                font-size: 40px;
                line-height: 50px;
                margin: 75px 0 0 0;
                text-align: center;
            }
}