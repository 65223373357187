﻿.umb-grid {
    max-width: 1920px;
    margin: 30px 0 30px 0;

    .row {
        margin-bottom: 25px;
    }

    p.caption {
        font-style: italic;
        margin: 10px 0 10px 0;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    a {
        color: $color-black;
        font-weight: 600;
        text-decoration: underline;
    }

    /*.wigdet_blauw {
        background-color: $color-blue;
        padding: 25px;
        a {
            color: $color-black;
            text-decoration: underline;
        }
        a:hover {
            font-weight: bold;
        }

        @include respond-to(sm)
            {
                margin: 0 0 20px 0;
            }
    }

    .widget_geel {
        background-color: $color-yellow;
        padding: 25px;
        a {
            color: $color-black;
            text-decoration: underline;
        }
        a:hover {
            font-weight: bold;
        }
        @include respond-to(sm)
            {
                margin: 0 0 20px 0;
            }
    }

    .widget_groen {
        background-color: $color-green;
        padding: 25px;
        a {
            color: $color-black;
            text-decoration: underline;
        }
        a:hover {
            font-weight: bold;
        }
        @include respond-to(sm)
            {
                margin: 0 0 20px 0;
            }
    }

    .widget_oranje {
        background-color: $color-orange;
        padding: 25px;
        a {
            color: $color-black;
            text-decoration: underline;
        }
        a:hover {
            font-weight: bold;
        }
        @include respond-to(sm)
            {
                margin: 0 0 20px 0;
            }
    }

    .widget_wit {
        background-color: $color-white;
        border: 1px solid $color-gray;
        padding: 25px;
        a {
            color: $color-black;
            text-decoration: underline;
        }
        a:hover {
            font-weight: bold;
        }
        @include respond-to(sm)
            {
                margin: 0 0 20px 0;
            }
    }

    .widget_rood {
        background-color: $color-red;
        padding: 25px;
        color: $color-white;
        a {
            color: $color-white;
            text-decoration: underline;
        }
        a:hover {
            font-weight: bold;
        }
        @include respond-to(sm)
            {
                margin: 0 0 20px 0;
            }
    }*/
}

.grid-submenu {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin: 0 0 20px 0;
    padding: 5px;
    list-style: none;
    text-transform: uppercase;
    font-family: $titel-font-family;
    font-weight: 100;
    font-size: 20px;
    ul {
        margin: 10px 0 10px 0;
        padding: 0 0 0 16px;
        list-style: square;
        text-transform: initial;
        font-family: $base-font-family;
        font-size: 16px;
    }

    li, li li {
        margin: 0;
    }

    li a, li li a {
        text-decoration: none;
        color: #000;
        font-weight: 100;
    }

    li a:hover, li li a:hover {
        text-decoration: underline;
    }
}