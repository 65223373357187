﻿.footerbanner_home {
    position: relative;
    background: $color-black-darker;
    max-width: 1920px;

    a.titel {
        color: $color-white;
        font-family:  $titel-font-family;
        font-size: 40px;
        line-height: 50px;
        position: absolute;
        bottom: 120px;
        right: 110px;
        z-index: 100;
        width: 60%;
        text-align: right;
        text-shadow: 0 0 15px #000000;
        text-decoration: none;
        @include respond-to(md)
            {
                font-size: 35px;
                line-height: 35px;
            }
        @include respond-to(sm) {
                font-size: 40px;
                line-height: 40px;
            }
        @include respond-to(xs) {
                font-size: 30px;
                line-height: 30px;
                right: 30px;
                width: 80%;
            }
    }

    a.btn-default {
        color: $color-white;
        padding: 10px 30px 10px 30px;
        position: absolute;
        bottom: 40px;
        right: 110px;
        z-index: 100;
        @include respond-to(md) {
            max-width: 60%;
        }
        @include respond-to(sm) {
            max-width: 60%;
            }
        @include respond-to(xs) {
            max-width: 80%;
            right: 30px;
            }
        
        }
    
    a:hover {
        text-decoration: none;
    }

}

.footerbanner_agenda {
    position: relative;
    overflow: hidden;

    a.titel {
        color: $color-white;
        font-family: 'Plak W01 Black Condensed';
        font-size: 60px;
        line-height: 52px;
        position: absolute;
        bottom: 120px;
        left: 100px;
        z-index: 9999;
        width: 60%;
        text-align: left;
    }

    a.btn-default {
        color: $color-white;
        padding: 10px 30px 10px 30px;
        position: absolute;
        bottom: 40px;
        left: 100px;
        z-index: 9999;
        }

    .img_voorgrond{
        position: absolute;
        bottom: -50px;
        left: 50px;
        z-index: 9000;
    }

}