﻿.zoek_container
{
    max-width: 1920px;

    .navbar-form
    {
        padding: 0;
        margin: 0 0 30px 0;
    }
}

.resultaten {
        margin: 0 0 20px 0;
        padding: 0;
        li {
            list-style: none;
            margin: 0 0 10px 0;

            border: 1px solid $color-black;
            a {
                padding: 10px 20px 10px 20px;
                display: block;
                text-decoration: none;
                p.titel {
                    color: $color-black;
                    font-family: $titel-font-family;
                    font-size: 25px;
                }
                p.omschrijving {
                    color: #868686;
                }
            }
        }
    }

.geenresultaat {
    color: $color-black;
    font-family: $titel-font-family;
    font-size: 25px;
    margin: 0 0 80px 0;
}