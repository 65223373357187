// =============================================================================
// _main.scss | Author: Nexwork
// =============================================================================

// Variables
$frontend-id:               "laagland";

// Put specific (color) settings for this site here.
$color-blue:                #0bbbef;
$color-blue-darker:         #0aa8d7;

$color-red:                 #e4032c;
$color-red-lighter:         lighten($color-red, 50%);
$color-red-darker:          #cc0000;

$color-yellow:              #f2cb4b;
$color-yellow-darker:       #d9ab14;

$color-green:               #6c9e6d;
$color-green-darker:        #578e59;
$color-green-lighter:       #9bca9c;

$color-black:               #222222;
$color-black-darker:        #000000;

$color-orange:              #ef7d00;
$color-orange-darker:       #e96300;

$color-white:               #ffffff;

$color-gray:                #dcdcdc;
$color-gray-lighter:        #f9f9f9;
$color-gray-dark:           #333333;


$pagination-color:              #222222;
$pagination-bg:                 #ffffff;
$pagination-border:             #222222;
$pagination-hover-color:        #ffffff;
$pagination-hover-bg:           #868686;
$pagination-hover-border:       #000000;
$pagination-active-color:       #f2cb4a;
$pagination-active-bg:          #000000;
$pagination-active-border:      #000000;
$pagination-disabled-color:     #ffffff;
$pagination-disabled-bg:        #868686;
$pagination-disabled-border:    #868686;


.color_0bbbef {color: #07617c;}
.color_e4032c {color: #7c0707;}
.color_f2cb4a {color: #222222;}
.color_6c9e6d {color: #244126;}
.color_222222 {color: #868686;}
.color_ef7d00 {color: #5c3b20;}

.bg_0bbbef {background: #07617c;}
.bg_e4032c {background: #7c0707;}
.bg_f2cb4a {background: #222222;}
.bg_6c9e6d {background: #244126;}
.bg_222222 {background: #868686;}
.bg_ef7d00 {background: #5c3b20;}

// Import Modules (order is important!)
@import 'modules/settings';
@import 'modules/mixins';
@import 'modules/fonts';
@import 'modules/forms';

// Import Pages
@import 'pages/homepage';
@import 'pages/content';
@import 'pages/agendaoverzicht';
@import 'pages/voorstelling';
@import 'pages/nieuws';
@import 'pages/zoeken';

// Import Partials
@import 'partials/header';
@import 'partials/menu';
@import 'partials/grid';
@import 'partials/quotes';
@import "partials/mailinglist";
@import 'partials/onzemensen';
@import 'partials/footer';
@import 'partials/footerbanners';
@import 'partials/slider';
@import 'partials/pagination';

// Import Userstyle
@import '../css/userstyle';

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.container-fluid {
    max-width: 1920px;
}

body
{
    max-width: 1920px;
    margin: 0 auto;
}